export const ListaPuntos = ({ bloque }) => {

    console.table(bloque);

    return (
        <>
            {bloque.datos.titulo !== "" && <p>{bloque.datos.titulo}</p>}
            <ul style={{ listStyleType: bloque.datos.tipoLista }}>
                {bloque.datos.lista.map((item, indexLista) => (
                    <li key={indexLista}>{item}</li>
                ))}
            </ul>
        </>
    );

};

export default ListaPuntos;