import React,{ useState, useRef} from 'react'     
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';
import Card  from '../../components/Card'

export const Pregunta = ({ pregunta }) => {

    const toast = useRef(null);
    const [respuestaSeleccionada, setRespuestaSeleccionada] = useState(0);
    const [preguntaContestada, setPreguntaContestada] = useState(false);
    const [estiloAlfa, setEstiloAlfa] = useState('');
    const [estiloBravo, setEstiloBravo] = useState('');
    const [estiloCharlie, setEstiloCharlie] = useState('');
    const [estiloDelta, setEstiloDelta] = useState('');

    const cambioRespuesta = (orden) => {
        setRespuestaSeleccionada(orden);
    }
    const contestarPregunta = () => {
        if(respuestaSeleccionada === 0){
            toast.current.show({ severity: 'error', summary: 'Error', detail: "No has marcado ninguna respuesta" });
            return;
        }
        setPreguntaContestada(true);

        switch(pregunta.respuestaCorrecta){
            case 1:
                setEstiloAlfa(' correcta');
                break;
            case 2:
                setEstiloBravo(' correcta');
                break;
            case 3:
                setEstiloCharlie(' correcta');
                break;
            case 4:
                setEstiloDelta(' correcta');
                break;
        }
        
        if(pregunta.respuestaCorrecta !== respuestaSeleccionada){
            switch(respuestaSeleccionada){
                case 1:
                    setEstiloAlfa(' erronea');
                    break;
                case 2:
                    setEstiloBravo(' erronea');
                    break;
                case 3:
                    setEstiloCharlie(' erronea');
                    break;
                case 4:
                    setEstiloDelta(' erronea');
                    break;
            }
        }
    }

    return (<>
        <Toast ref={toast} />
        <Card className="card p-4">
            <Card.Header>
                <Card.Header.Title>
                    <h4 className="card-title">{pregunta.enunciado}</h4>
                </Card.Header.Title>
            </Card.Header>
            <Card.Body className="ml-4 mt-4">
            <div className="respuestas flex flex-column">
                    <div className="d-flex align-items-left mb-2">
                        <RadioButton disabled={preguntaContestada}
                            inputId='pregunta'
                            name="respuesta"
                            value={1}
                            onChange={(e) => cambioRespuesta(e.value)}
                            checked={respuestaSeleccionada === 1}
                        />
                        <label className={'ml-2' + estiloAlfa}>
                            {pregunta.alfa}
                        </label>
                    </div>
                    <div className="d-flex align-items-left mb-2">
                        <RadioButton disabled={preguntaContestada}
                            inputId='pregunta'
                            name="respuesta"
                            value={2}
                            onChange={(e) => cambioRespuesta(e.value)}
                            checked={respuestaSeleccionada === 2}
                        />
                        <label className={'ml-2' + estiloBravo}>
                            {pregunta.bravo}
                        </label>
                    </div>
                    <div className="d-flex align-items-left mb-2">
                        <RadioButton disabled={preguntaContestada}
                            inputId='pregunta'
                            name="respuesta"
                            value={3}
                            onChange={(e) => cambioRespuesta(e.value)}
                            checked={respuestaSeleccionada === 3}
                        />
                        <label className={'ml-2' + estiloCharlie}>
                            {pregunta.charlie}
                        </label>
                    </div>
                    <div className="d-flex align-items-left mb-2">
                        <RadioButton disabled={preguntaContestada}
                            inputId='pregunta'
                            name="respuesta"
                            value={4}
                            onChange={(e) => cambioRespuesta(e.value)}
                            checked={respuestaSeleccionada === 4}
                        />
                        <label className={'ml-2' + estiloDelta}>
                            {pregunta.delta}
                        </label>
                    </div>
                    {preguntaContestada == false && (
                        <div className="mt-4">
                            <button type='button' className='btn btn-hover iq-button ml-4 boton-test' onClick={contestarPregunta}><i className="fa fa-question mr-1" aria-hidden="true"></i>Corregir</button>
                            <button type='button' className='btn btn-hover iq-button ml-4 boton-test-movil' onClick={contestarPregunta}><i className="fa fa-question mr-1" aria-hidden="true"></i></button>
                        </div>
                    )}
                    {preguntaContestada == true && pregunta.ayuda !== '' && (
                        <div className="alert alert-primary">
                            <p>{pregunta.ayuda}</p>
                        </div>
                    )}
                </div>
            </Card.Body>
        </Card>
    </>);
};


export default Pregunta;