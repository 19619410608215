import React,{ useState,useRef, useEffect} from 'react'
import {Container,Row,Col,OverlayTrigger,Tooltip} from 'react-bootstrap'
import Card  from '../../../components/Card'
//axios
import axios from 'axios'
//skeleton
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';   
import api from '../../../interceptor/axios';
import {descargarPDF} from '../../../utils/descargarPDF';

import cabecera from '../../../assets/images/entrenamientos.jpg'
import fav1 from '../../../assets/images/favorite/01.jpg'
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade,Navigation,Thumbs,Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([EffectFade,Navigation,Thumbs,Pagination]);

const Entrenamiento = ({entrenamiento, oposicion}) => {

    //montamos el titulo con fecha_inicio - fecha_fin en formato dd/mm/yyyy
    //formateamos las fechas porque vienen enformato yyyy-mm-dd
    const toast = useRef(null);
    const baseUrl = process.env.REACT_APP_ENTRENAMIENTOS_URL + entrenamiento.slug + "/";
    const [slugEntrenamiento, setSlugEntrenamiento] = useState(baseUrl);
    const [pdfIniciacion, setPdfIniciacion] = useState(null);
    const [pdfAvanzado, setPdfAvanzado] = useState(null);
    const [pdfExtra, setPdfExtra] = useState(null);
    const [videoCarrera, setVideoCarrera] = useState(null);
    const [videoGimnasio, setVideoGimnasio] = useState(null);
    const [videoPiscina, setVideoPiscina] = useState(null);
    
    useEffect(() => {
        if (entrenamiento && entrenamiento.slug) {
            if(oposicion === "1"){
                // CCVV
                setPdfIniciacion(entrenamiento.pdf_ccvv_iniciacion || null);
                setPdfAvanzado(entrenamiento.pdf_ccvv_avanzado || null);
                setPdfExtra(entrenamiento.pdf_ccvv_extra || null);
            } else {
                // SEPEI
                setPdfIniciacion(entrenamiento.pdf_sepei_iniciacion || null);
                setPdfAvanzado(entrenamiento.pdf_sepei_avanzado || null);
                setPdfExtra(entrenamiento.pdf_sepei_extra || null);
            }
            setVideoCarrera(entrenamiento.video_carrera || null);
            setVideoGimnasio(entrenamiento.video_gimnasio || null);
            setVideoPiscina(entrenamiento.video_piscina || null);
            setSlugEntrenamiento(entrenamiento.slug);
        }
    }, [entrenamiento.slug, oposicion]);

    const descargarEntrenamiento = async (slugPdf, nombre) => {
        
        try{
           await descargarPDF('E', slugEntrenamiento + "|" + slugPdf, nombre);
        } catch (error) {
           toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
        }
      };   
    

    
    return (
        <Col sm="12">
            <Card className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-body">
                    <div className="favorites-contens">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="main-title">{entrenamiento.titulo}</h4>
                            </div>
                        </div>
                        <div className="iq-card-body">
                            <div className='d-flex justify-content-between flex-wrap flex-md-nowrap'>
                                {videoCarrera !== null && (
                                    <Col sm="4">
                                        Vídeo de carrera
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <video key={`${baseUrl}${videoCarrera}`} width="100%" controls controlsList="nodownload">
                                                <source src={`${baseUrl}${videoCarrera}`} type="video/mp4" />
                                            </video>
                                        </div>
                                    </Col>
                                )}
                                {videoGimnasio !== null && (
                                    <Col sm="4">
                                        Vídeo de gimnasio
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <video key={`${baseUrl}${videoCarrera}`} width="100%" controls controlsList="nodownload">
                                                <source src={`${baseUrl}${videoGimnasio}`} type="video/mp4" />
                                            </video>
                                        </div>
                                    </Col>
                                )}
                                {videoPiscina !== null && (
                                    <Col sm="4">
                                        Vídeo de piscina
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <video key={`${baseUrl}${videoCarrera}`} width="100%" controls controlsList="nodownload">
                                                <source src={`${baseUrl}${videoPiscina}`} type="video/mp4" />
                                            </video>
                                        </div>
                                    </Col>
                                )}
                            </div>
                            <div className='d-flex justify-content-between flex-wrap flex-md-nowrap'>
                                {pdfIniciacion !== null && (
                                    <button type='button' className='btn btn-hover iq-button mt-4' onClick={() => descargarEntrenamiento(pdfIniciacion, "iniciacion")}><i className="fa fa-play mr-1" aria-hidden="true"></i>Descargar entrenamiento iniciación</button>
                                )}
                                {pdfAvanzado !== null && (
                                    <button type='button' className='btn btn-hover iq-button mt-4' onClick={() => descargarEntrenamiento(pdfAvanzado, "avanzado")}><i className="fa fa-play mr-1" aria-hidden="true"></i>Descargar entrenamiento avanzado</button>
                                )}
                                {pdfExtra !== null && (
                                    <button type='button' className='btn btn-hover iq-button mt-4' onClick={() => descargarEntrenamiento(pdfExtra, "extra")}><i className="fa fa-play mr-1" aria-hidden="true"></i>Descargar entrenamiento extra</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </Col>
    );
};

const Entrenamientos = () => { 
       
    const restUrlBase = process.env.REACT_APP_REST_URL;
    const toast = useRef(null);
    const [oposicion, setOposicion] = useState(null);
    const [listaEntrenamientos, setListaEntrenamientos] = useState(null);
    const [listaVideosFijos, setListaVideosFijos] = useState(null);
    const [slugListaVideosFijos, setSlugListaVideosFijos] = useState(null);
    const [loading, setLoading] = useState(false);
    const [entrenamiento, setEntrenamiento] = useState(null);
    

      if(listaEntrenamientos === null){

        api.post(restUrlBase + "entrenamientos.php", { })
        .then(response => {
  
            if (response.data.success) {
                setListaEntrenamientos(response.data.listaEntrenamientos);
                setOposicion(response.data.oposicion);        
                //si hay entrenamientos, seleccionamos el primero
                if(response.data.listaEntrenamientos.length > 0){
                    setEntrenamiento(response.data.listaEntrenamientos[0]);
                }   
                //si hay videos fijos, los cargamos
                if(response.data.listaVideosFijos.length > 0){
                    setListaVideosFijos(response.data.listaVideosFijos);
                    setSlugListaVideosFijos(response.data.slugListaVideosFijos);
                }             
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
            }
           
        })
        .catch(error => {
           toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
        });

        return (
            <>
                <Toast ref={toast} />
                <div className="card flex justify-content-center loading">
                    <ProgressSpinner />
                </div>
            </>
        );
    } else {
        if(loading){
            return (
                <div className="card flex justify-content-center loading">
                    <ProgressSpinner />
                </div>
            );
        } else {

                return (
                    <>
                    

                    <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{backgroundImage: `url(${cabecera})`}}>  
                        <Container fluid> 
                            <Row className="align-items-center">
                                <Col sm="12">
                                    <nav className="text-center iq-breadcrumb-two">
                                        <h2 className="title">ENTRENAMIENTOS</h2>
                                    </nav>
                                </Col>
                            </Row> 
                        </Container>
                     </div>
                     {listaVideosFijos !== null && (
                        <section class="mt-4">
                            <Container fluid>
                                <Row>
                                    <Col sm="12" className="overflow-hidden">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h4 className="main-title">Vídeos fijos</h4>                 
                                        </div>
                                        <div id="favorites-contens">
                                            <div id="prev" className="swiper-button swiper-button-prev"><i className= "fa fa-chevron-left"></i></div>
                                            <div id="next" className="swiper-button swiper-button-next"><i className= "fa fa-chevron-right"></i></div>
                                            <Swiper  
                                                navigation={{
                                                    prevEl: '#prev',
                                                    nextEl: '#next'
                                                }} 
                                                breakpoints={{
                                                    320: { slidesPerView: 1 },
                                                    550: { slidesPerView: 2 },
                                                    991: { slidesPerView: 2 },
                                                    1400: { slidesPerView: 2 }
                                                }}
                                                loop={true}  
                                                slidesPerView={2} 
                                                spaceBetween={20}  
                                                as="ul" 
                                                className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">

                                                {listaVideosFijos
                                                    .map((video, indexVideo) => (
                                                    
                                                    <SwiperSlide as="li" key={indexVideo}>
                                                        <video width="100%" controls controlsList="nodownload">
                                                            <source src={`${slugListaVideosFijos}${video.slug}`} type="video/mp4" />
                                                        </video>
                                                        <h6 className="iq-title enlace">{video.titulo}</h6>
                                                        <p>{video.descripcion}</p>
                                                    </SwiperSlide>                                                     
                                                ))}
                                            </Swiper>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    )}

                    {entrenamiento !== null && (
                    <div className='mt-4'>
                        <Toast ref={toast} />

                        <Container fluid>
                            <Row>
                            <Col sm="12">
                                <Entrenamiento entrenamiento={entrenamiento} oposicion={oposicion} />
                            </Col>
                            </Row>
                        </Container>
                    </div>
                    )}
                     <div>   
                        <Container fluid>
                            <Row>
                            <Col sm="12">
                                <h4 className="card-title mt-4">Entrenamientos disponibles</h4>
                                <div className="table-view">
                                    <table className="data-tables table movie_table " style={{width:"100%"}}>
                                        <thead>
                                            <tr>
                                                <th style={{width:"70%"}}>Entrenamiento</th>
                                                <th style={{width:"30%"}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {listaEntrenamientos.map((entrenamiento, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{entrenamiento.titulo}</td>
                                                    <td>
                                                    <button type='button' className='ml-4 btn btn-hover iq-button' onClick={() => setEntrenamiento(entrenamiento)}><i className="fa fa-play mr-1" aria-hidden="true"></i>Consultar</button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                                        
                                </Col>
                                
                            </Row>
                        </Container>
            
                    </div>
                    </>
                )
                    
            }

    
        }
}
export default Entrenamientos;